import config from 'Data';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Layout, Head } from 'Common';

const NotFoundPage = () => (
  <Layout>
    <Head type="Organization">404 | {config.legalName}</Head>
    <Center>
      <h1>404 PAGE NOT FOUND</h1>
      <Link to="/">Go Back Home</Link>
    </Center>
  </Layout>
);

const Center = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 4rem 0;
  text-align: center;
`;

export default NotFoundPage;
